// export const GET_EARLIEST_DELIVERY_DATE_URL = `d/control/getEarliestDeliveryDays?productIds=`;

import { COUNTRIES, CURRENCY_ID } from "../../constants/common/common";
import { plpPageConstants } from "../../constants/pageConstants/plpConstants";
import { removeEmptyNullAndUndefinedFromObject } from "../../utils/common";

/**
 *
 * @param {*} page
 * @param {*} size
 * @param {*} geoId
 * @param {*} categoryUrl
 * @param {*} lang
 * @param {*} currency
 * @param {*} domainId
 * @param {*} isFacetEnabled
 * @param {*} quickFilter
 * @param {*} isFromApp
 * @returns
 */
export const GET_PRODUCT_LISTING_URL = ({
  page = plpPageConstants?.INITIAL_PAGE,
  size = plpPageConstants?.DESKTOP_PRODUCT_PER_PAGE,
  geoId = COUNTRIES?.INDIA,
  categoryUrl,
  lang = "en",
  currency = CURRENCY_ID,
  domainId = "fnp.com",
  isFacetEnabled = true,
  quickFilter = true,
  filters = undefined,
  sortOption = undefined,
  pageType = "",
  qs = "",
  pincode = "",
  isOnScroll = false,
}) => {
  const localParams = {
    page,
    size,
    geoId,
    categoryUrl,
    lang,
    currency,
    domainId,
    isFacetEnabled,
    quickFilter,
    fq: filters,
    sortOption,
    pincode,
    qs,
  };
  if (pageType === plpPageConstants?.SEARCH) {
    localParams.pageType = pageType;
    localParams.qs = qs;
    localParams.isFacetEnabled = false;
    localParams.isOnScroll = isOnScroll;
  } else {
    localParams.categoryUrl = categoryUrl;
  }

  const filteredParams = removeEmptyNullAndUndefinedFromObject(localParams);
  const flattenedParams = new URLSearchParams(filteredParams).toString();
  return `/columbus/v1/productList?${decodeURIComponent(flattenedParams)}`;
};
/**
 *
 * @param {*} categoryID
 * @param {*} geoId
 * @param {*} size
 * @returns
 */
export const GET_PLP_REVIEWS_URL = (categoryID, geoId = "fnp.com", size = 12) => {
  return `/moody/v1/category/reviews?categoryId=${categoryID}&geoId=${geoId}&size=${size}`;
};

/**
 *
 * @param {*} productIDs
 * @returns
 */
export const GET_EARLIEST_DELIVERY_DATE_URL = (productIDs = "", pincode = "") => {
  return `/d/control/getEarliestDeliveryDays?productIds=${productIDs}${pincode ? `&pincode=${pincode}` : ""}`;
};

/**
 * @param {*}
 * @returns
 */
export const GET_PRODUCT_ATTR_TAG_URL = () => {
  return `/chocolate/v1/global/static/product_attr_tag`;
};

/**
 * @param {*} category
 * @returns
 */
export const GET_META_TAGS_URL = (category) => {
  return `/chocolate/v1/category/${category}`;
};

/**
 * @param {*} productIds
 * @returns
 */
export const GET_WIDGET_PRODUCTS_URL = (productIds) => {
  return `/columbus/v1/products?productIds=${productIds}&domainId=fnp.com&currency=INR`;
};

/**
 * @param {*} catalogueId
 * @returns
 */
export const GET_SEARCH_INSTEAD = (catalogueId) => {
  return `/chocolate/v1/global/static/${catalogueId}/search-instead`;
};

/**
 * Returns the URL for fetching analytics data.
 *
 * @returns {string} The analytics data URL.
 */
export const PLP_HEAD_ANALYTICS = () => {
  return "/chocolate/v1/analytics/new-plp-headanalytics";
};
